import React from 'react'
import styles from './AnotherPgs.module.css'

const AnotherPgs = () => {
  return (
    <div className={styles.center}>
        아직 등록된 페이지가 없습니다.
    </div>
  )
}

export default AnotherPgs