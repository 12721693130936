import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './page/Home/Home';
import AppLayout from './layout/AppLayout/AppLayout';
import AnotherPgs from './page/AnotherPgs/AnotherPgs';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<AppLayout />}>
          <Route index element={< Home />} />
          <Route path='another-pgs' element={<AnotherPgs />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
