import React from 'react'
import styles from './Home.module.css'

const Home = () => {
  return (
    <div className={styles.center}>
      <div className={styles.typing_mode}>
        <p>lay out an idea.</p>
      </div>

    </div>
  )
}

export default Home