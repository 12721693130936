import React, { useRef, useState } from 'react'
import styles from './AppLayout.module.css'
import { Outlet } from 'react-router-dom'
import Logo from '../../svg/download.svg'

const AppLayout = () => {
  const [pay, setPay] = useState(false)

  const ref = useRef()

  return (
    <div>
      <div className={`${styles.navbar_cont} ${styles.navbar_m_cont}`}>
        <img className={styles.nav_logo_styles} src={Logo} onClick={() => window.location.href = '/'} />

        <div className={styles.navbar_lv1}>
          <p onClick={() => window.location.href = '/another-pgs'}>다른 페이지 둘러보기</p>
          <p onClick={() => setPay(true)}>견적 문의</p>
        </div>
        {pay === true && (
          <div className={styles.pay_cont}>

          </div>
        )}
      </div>
      <Outlet />
    </div>
  )
}

export default AppLayout